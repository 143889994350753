
export default [
    {
        path: '/dashboard',
        name: 'dashboard.index',
        component: () => import('../views/dashboard/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/profile',
        name: 'dashboard.profile',
        component: () => import('../views/dashboard/Profile.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing',
        name: 'dashboard.billing',
        component: () => import('../views/dashboard/billing/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing/select-plan',
        name: 'dashboard.billing.select-plan',
        component: () => import('../views/dashboard/billing/SelectPlan.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing/checkout',
        name: 'dashboard.billing.checkout',
        component: () => import('../views/dashboard/billing/Checkout.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/inspections/choose',
        name: 'dashboard.inspection',
        component: () => import('../views/dashboard/inspection/Inspection.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/inspections/create-residential',
        name: 'dashboard.inspection/residential',
        component: () => import('../views/dashboard/inspection/Residential.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/inspections/create-commercial',
        name: 'dashboard.inspection/commercial',
        component: () => import('../views/dashboard/inspection/Commercial.vue'),
        meta: { auth: true }
    },
 //  {
 //      path: '/dashboard/inspections/choose',
 //      name: 'dashboard.inspection',
 //      component: () => import('../views/dashboard/inspection/Inspection.vue'),
 //      meta: { auth: true }
 //  },
]
